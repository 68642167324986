import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VideoBackground from "vue-responsive-video-background-player";
import VueCoreVideoPlayer from 'vue-core-video-player'

import("vuetify/dist/vuetify.min.css")
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { inject } from '@vercel/analytics';
 
inject();

Vue.config.productionTip = false
Vue.component("video-background", VideoBackground);
Vue.use(VueCoreVideoPlayer)

new Vue({
  router,
  store,
  vuetify,

  render: h => h(App)
}).$mount('#app')
