<template>
    <div class="image-gallery">
      <div class="row" v-for="(row, index) in computedRows" :key="index">
        <div class="image-container" v-for="image in row" :key="image.id" @click="navigate(image.path)">
          <img :src="image.src" :alt="image.alt" class="image"/>
          <div class="work-title">{{ image.title }}</div>
          <div class="work-center">{{ image.center }}</div>
          <div class="work-extra">{{ image.type }}: {{ image.location }}</div>
          <div class="work-extra">{{ image.date }}</div>
        </div>
      </div>
      <div :key="$route.fullPath">
        <v-btn
          class="custom-btn"
          color="white"
          elevation="2"
          outlined
          to="/work"
        >
            See More
          </v-btn>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageGallery',
    data() {
        return {
        images: [
            { id: 1, src: require('@/assets/diamantes/diamantes.jpg'), alt: 'Image 1', title: "INFINITE DIAMONDS", center: 'Bling Bling Barcelona Nightclub', type:"Permanent installation", location:"St/ de Tuset, 8-10,  Barcelona", date:"November, 2023", path:"/work/diamonds"},
            { id: 2, src: require('@/assets/rhudo/CuboYGente.png'), alt: 'Image 2', title: " THE CUBE", center: 'Rhudo Restaurant', type:"Permanent installation", location:" St/  de Velázquez, 64, Madrid", date:" January, 2024", path:"/work/rhudo"},
            { id: 3, src: require('@/assets/cave/thecave1.jpg'), alt: 'Image 3', title: "THE CAVE", center: 'Opium Barcelona Nightclub', type:"Permanent installation", location:" Pg. Marítim de la Barceloneta, 34, Barcelona", date:"October, 2023", path:"/work/thecave"},
            { id: 1, src: require('@/assets/eden/eden1.jpg'), alt: 'Image 1', title: "EDÉN", center: 'Chapel of Grace', type:"Temporary installation", location:"Street des Coll des Palmer, 2, 07730, Alaior, Menorca", date:"June, 2024", path:"/work/eden"},
            { id: 2, src: require('@/assets/japan/Jpn4.png'), alt: 'Image 2', title: "DEJITARU HENKAN-SŌ", center: 'Itinerant', type:"Temporary installation", location:"Tokyo, Kyoto & Osaka", date:"March, 2024", path:"/work/dejitaruhenkanso"},
            { id: 9, src: require('@/assets/ideal/ideal12.jpg'), alt: 'Image 9', title: "INFINITE STAIRS", center: 'IDEAL Digital Arts Center of Barcelona', type:"Temporary installation", location:" St/ del Dr. Trueta, 196, 198, Barcelona", date:"June, 2022", path:"/work/ideal"},
        ],
        }
    },
    methods: {
      navigate(path) {
          if (path) {
            this.$router.push(path);
          }
      }
    },
    computed: {
      computedRows() {
        let rows = [];
        for (let i = 0; i < this.images.length; i += 3) {
          rows.push(this.images.slice(i, i + 3));
        }
        return rows;
      }
    }
  }
  </script>
  
  <style scoped>
.image-gallery {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: black;
}
.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
  width: 90%;
}

.image-container {
  flex: 1 1 calc(33.333% - 30px); /* Adjust width minus margin */
  padding: 3vw;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 400px; /* Minimum width */
  min-height: 100px; /* Minimum width */
  
}

.image {
  width: 100%; /* Ensures the image is as wide as its container */
  height: 100%; /* Ensures the image is as tall as its container */
  object-fit: cover; /* Covers the container, may crop the image */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.image:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.work-center {
  font-family: "poppin-thin";
  text-align: left; /* Align text to the left */
  font-size: 1rem;
  color: white;
}
.work-extra {
  font-family: "poppin-thin";
  text-align: left; /* Align text to the left */
  font-size: 0.9rem;
  color: white;
}
.work-title {
  font-family: "poppin-thin";
  padding-top: 2vh;
  text-align: left; /* Align text to the left */
  font-size: 1.2rem;
  color: white;
}
.custom-btn {
  color: white; /* Text color */
  border-color: white; /* Border color */
}

.custom-btn:hover {
  background-color: #fc00003e !important; /* Background color on hover */
  color: white !important; /* Text color on hover */
}
@media (max-width: 100) {
  .image-container {
    flex: 1 1 50%; /* Full width for smaller screens */
  }
}
/* To set up the font family*/
@font-face {
    font-family: "poppin-thin";
    src: url('~@/assets/fonts/Poppins-Thin.ttf');
}
  </style>
  