<template>
    <footer class="footer-bar">
      <div class="footer-container">
        <router-link to="/" class="logo">
          <img :src="logo" alt="Company Logo">
        </router-link>
        
        <div class="copyright-and-privacy">
            <span>© NESSAES</span>
            <router-link to="/privacy_policy" class="privacy-policy-link">
                Privacy Policy
            </router-link>
        </div>
  
        <div class="social-icons">
          <a href="https://es.linkedin.com/in/julia-castillo-esquina" target="_blank" aria-label="Linkedin">
            <img src="@/assets/logos/linkedin.png" alt="Linkedin">
          </a>
          <!-- Uncomment and add other social icons as needed -->
        </div>
      </div>
    </footer>
  </template>
  
  
  <script>
  export default {
    name: 'FooterBar',
    data() {
      return {
        logo: require('@/assets/nessaeswhitelogo.png'),
      }
    },
  }
</script>
  
<style scoped>
.footer-bar {
  background-color: black; /* Dark background for contrast */
  color: #fff;
  position: flex; /* Keeps the footer at the bottom of the viewport */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; /* Use flexbox to center the footer-container */
  justify-content: center; /* Center the footer-container horizontally */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%; /* Width is 90% of the footer-bar */
  height: 5vh;
}
  
  .logo img {
    margin-top: min(1vh, 5px);
    height: min(2vh, 15px); /* Adjust size as needed */
  }
  
  .social-icons a {
    margin: 0 5px; /* Adjust spacing as needed */
  }
  
  .social-icons img {
    height: min(2vh, 15px); /* Adjust size as needed */
    width: min(2vh, 15px); /* Adjust width as needed */
  }
.privacy-policy-link {
  font-family: "poppin-thin";
  color: white; /* Text color */
  text-decoration: none; /* Removes underline */
  padding-left: 10px; /* Adds spacing between the copyright text and the link */
}

/* Additional style to align items in one line */
.copyright-and-privacy {
  margin-left: -55px;
  font-family: "poppin-thin";
  display: flex; /* Aligns children inline */
  align-items: center; /* Centers items vertically */
  gap: 10px; /* Adds a gap between items */
}

@font-face {
    font-family: "poppin-thin";
    src: url('~@/assets/fonts/Poppins-Thin.ttf');
}
  </style>
  