<template>
    <div class="video-intro" aling="center" justify="center">
      <video-background :src="bgVideo" class="video-background">
        <div class="box">
          <div class="vue-title">NESSAES</div>
          <div class="vue-subtitle">CREATIVE DIRECTOR & DIGITAL ARTIST</div>
        </div>  
      </video-background>
    </div>
</template>

<script>
export default {
  name: 'VideoIntro',
  props: ['bgVideo'],
}
</script>

<style scoped>
.video-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh; /* Full viewport height */
}
.video-background {
  width: 100%;
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Cover the full area without losing aspect ratio */
}
.box {
  background: rgba(255, 255, 255, 0.005);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -35%);
  width: calc(min(60vw, 60vh));  /* Adjust the width as needed */
  height: calc(min(48vw, 48vh)); /* Adjust the height as needed */
  min-width: 200px;      
  min-height: 200px;
  backdrop-filter: blur(5px);
  /* Invert the triangle by adjusting clip-path */
  clip-path: polygon(0 0, 100% 0, 50% 100%);

  display: flex;           
  align-items: center;     
  flex-direction: column;  
  justify-content: top; 
  padding: 10px;           
  overflow: hidden;        
}

.vue-title, .vue-subtitle {
  font-family: "poppin-thin";
  color: white;
  font-weight: bold;
  text-align: center; /* Center the text */
  width: 100%; /* Ensure text does not exceed the box */
  padding: 0 20px; /* Add padding inside the box */
}

.vue-title {
  margin-top: 6vh; /* Adjust top margin to ensure it's within the triangle */
  font-size: calc(2.5vw + 10px); /* Adjust font size for responsiveness */
  letter-spacing: 2px; /* Adjust letter-spacing as needed */
  max-width: 75%; /* Limit max width to ensure it stays within the triangle */
}
  
.vue-subtitle {
  font-size: calc(0.5vw + 5px); /* Smaller font size for the subtitle */
  margin-top: 10px; /* Space between title and subtitle */
  max-width: 50%; /* Subtitle max-width to ensure it fits */
}



@font-face {
    font-family: "poppin-thin";
    src: url('~@/assets/fonts/Poppins-Thin.ttf');
}
</style>