<template>
  <div class="landing-page">
    <app-navbar></app-navbar>
    <video-intro :bgVideo="main.bgVideo"></video-intro>
    <image-gallery></image-gallery>
    <footer-bar></footer-bar>
  </div>  
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import VideoIntro from '@/components/VideoIntro.vue';
import ImageGallery from '@/components/ImageGallery.vue';
import FooterBar from '@/components/FootPage.vue';


export default {
  name: 'LandingPage',
  components: {
    AppNavbar,
    VideoIntro,
    ImageGallery,
    FooterBar,
  },
  data() {
    return {
      main: {
        bgVideo: require('@/assets/video_home.mp4'),
      },
    }
  },
}
</script>

<style scoped>
.landing-page {
  font-family: 'Montserrat', sans-serif;
}
</style>
