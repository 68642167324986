<template>
    <nav class="navbar">
      <div class="nav-container">
        <router-link to="/" class="logo"><img :src="logo" alt="Company Logo"></router-link>
        <ul class="nav-links">
          <li><router-link to="/work">WORK</router-link></li>
          <li><router-link to="/about">ABOUT</router-link></li>
          <li><router-link to="/contact">CONTACT</router-link></li>
        </ul>
      </div>
    </nav>
</template>

<script>
export default {
  name: 'AppNavbar',
  data() {
    return {
      logo: require('@/assets/nessaeswhitelogo.png'),
    }
  },
}
</script>

<style scoped>
.navbar {
  background-color: black; /* Dark background for contrast */
  color: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  height: 7%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.nav-links {
  font-family: "poppin-thin";
  font-size: calc(8px + 1vh);
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #b94242; /* Vue green for hover effect */
}
.logo {
  display: flex;
  align-items: center; /* This aligns the logo vertically within the navbar */
  justify-content: center; /* Center the logo if there's any extra space horizontally */
  height: 100%; /* Match the height of the navbar */
}
.logo img {
  height: 25%; /* Adjust based on your logo */
  min-height: 20px; /* 25% of the parent's minimum height of 200px */
  max-height: 20px; /* 25% of the parent's maximum height of 800px */
}

/* To set up the font family*/
@font-face {
    font-family: "poppin-thin";
    src: url('~@/assets/fonts/Poppins-Thin.ttf');
}
</style>
  